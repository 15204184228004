import { graphql } from 'gatsby';
import React from 'react';
import { CategoryQuery } from '../graphql-generated';
import { useCategoryActivation } from '../hooks/useCategoryActivation';
import { PageProps } from '../types/pageProps';
import { ListPage } from './listPage';

const Category: React.FC<PageProps<CategoryQuery>> = ({ data }) => {
  const category = data.category!;
  const title = category.name ?? '';

  useCategoryActivation(category?.name);

  return (
    <ListPage
      data={{
        page: {
          ...data.category,
          title,
          excerpt: category.description,
        } as any,
      }}
      fromCategory
      showTitle={true}
    />
  );
};

export const query = graphql`
  query CategoryQuery($id: String!) {
    category: wpCategory(id: { eq: $id }) {
      name
      count
      description
      acfPageLayout {
        sidebar {
          content {
            ...CategorySidebarContent
          }
        }
        mainSection {
          content {
            ...CategoryMainSectionContent
          }
        }
        bottomSection {
          content {
            ...CategoryBottomSectionContent
          }
        }
      }
    }
  }
`;

export default Category;
