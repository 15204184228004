import { useEffect, useContext } from 'react';
import { CategoriesContext } from '../contexts';

export const useCategoryActivation = (categoryName?: string | null) => {
  const { setActiveCategory } = useContext(CategoriesContext);

  useEffect(() => {
    setActiveCategory(categoryName);
    return setActiveCategory;
  }, [categoryName]);
};
